/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { COLOR } from '../../utils/constants';
import ratesData from '../../data/json/Rates/rates.json';
import './_rates-box.scss';

const RatesBox = props => {
  const { className, type, fullWidth, darkBlue, size, style } = props;
  const pageData = ratesData.find(rate => rate.component === type);

  return (
    <div
      className={classnames(
        `rates-box`,
        { [`rates-box--full-width`]: fullWidth || size === 'fullwidth' },
        {
          [`rates-box--dark-blue`]:
            darkBlue || type === 'unsecured' || type === 'fixed-unsecured'
        },
        { [`rates-box--small`]: size === 'small' },
        className
      )}
      style={style}
    >
      {pageData.rates.map((rate, index) => (
        <div
          key={index}
          className={`rate-item ${!rate.rate2 ? 'rate-item--centered' : ''}`}
        >
          <div className="rate-item__label">{rate.label}</div>
          <div className="rate-item__items">
            <Rate rate={rate.rate1} index={index} />
            {rate.rate2 && (
              <React.Fragment>
                {/* Change label based on type of loan (e.g. motor loans are currently fixed) */}
                <div className="rate__to">{type !== 'motor' ? 'to' : 'or'}</div>
                <Rate rate={rate.rate2} index={index} />
              </React.Fragment>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const Rate = props => (
  <div className="rate">
    <div className="rate__value">{props.rate}</div>
    <div className="rate__percentage-container">
      <div className="rate__percentage">%</div>
      <div className="rate__pa">p.a.</div>
      <div className="rate__footnotesymbol">
        {props.index === 0 ? '^' : '*'}
      </div>
    </div>
  </div>
);

RatesBox.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  type: 'motor'
};

//  BRANDED ////////////////////////////////////////////////////////////////////

const RateBoxBranded = props => {
  const { className, type, fullWidth, darkBlue, size, style } = props;
  const pageData = ratesData.find(rate => rate.component === type);

  // themes // TODO get vales form constants
  let themes = {};
  const fgSecondary = '#0046aa';

  themes.primary = {
    border: '#DDDDDD',
    titleBg: COLOR.BLUE,
    titleFg: '#fff',
    itemBg: '#f8f8f8',
    itemFg: COLOR.BLUE
  };

  themes.secondary = {
    border: '#DDDDDD',
    titleBg: '#0046aa',
    titleFg: '#fff',
    itemBg: '#f8f8f8',
    itemFg: fgSecondary
  };

  themes.bluePrimary = {
    border: '#cfe9ff',
    titleBg: COLOR.BLUE,
    titleFg: '#fff',
    itemBg: '#e8f4ff',
    itemFg: COLOR.BLUE
  };

  themes.blueSecondary = {
    ...themes.bluePrimary,
    titleBg: '#62b8ff',
    titleFg: '#000',
    itemFg: fgSecondary
  };

  themes.purple = {
    border: '#e5e3fc',
    titleBg: COLOR.PURPLE,
    itemBg: '#f2f2fe',
    itemFg: COLOR.BLUE
  };
  themes.purpleSecondary = {
    ...themes.purple,
    titleBg: '#d4d3fa',
    itemFg: fgSecondary
  };

  themes.pink = {
    border: '#ffddef',
    titleBg: COLOR.PINK_LIGHT,
    itemBg: '#ffeff7',
    itemFg: COLOR.BLUE
  };
  themes.pinkSecondary = {
    ...themes.pink,
    titleBg: '#ffc8e4',
    itemFg: fgSecondary
  };

  themes.turquoise = {
    border: '#cfe9ff',
    titleBg: '#11dbf2',
    itemBg: '#e8fafe',
    itemFg: COLOR.BLUE
  };
  themes.turquoiseSecondary = {
    ...themes.turquoise,
    titleBg: '#b7f3fb',
    itemFg: fgSecondary
  };

  themes.grey = {
    border: '#e5e5e5',
    titleBg: '#c1c1c1',
    itemBg: '#f2f2f2',
    itemFg: COLOR.BLUE
  };

  themes.greySecondary = {
    ...themes.grey,
    titleBg: '#d6d6d6',
    itemFg: fgSecondary
  };

  const theme = !props.theme ? themes.primary : themes[props.theme];

  const RatesBox = styled.div`
    background-color: ${theme.titleFg};

    && {
      border-color: ${theme.border};
    }
  `;

  const Title = styled.div`
    && {
      background-color: ${theme.titleBg};
      color: ${theme.titleFg || '#000'};
    }
  `;

  const Item = styled.div`
    && {
      &:nth-child(2n + 1) {
        background-color: ${theme.itemBg};
        border-top: 1px solid ${theme.border};
      }
      .rate {
        color: ${theme.itemFg};
      }
    }
  `;

  return (
    <RatesBox
      {...props}
      className={classnames(
        'rates-box brand',
        { [`rates-box--full-width`]: fullWidth || size === 'fullwidth' },
        { [`rates-box--small`]: size === 'small' }
      )}
    >
      <Title className={`rates-box__title ${props.className}`}>
        <div className="rates-box__title-text">{pageData.title}</div>
      </Title>

      {pageData.rates.map((rate, index) => (
        <Item
          key={index}
          className={`rate-item ${!rate.rate2 ? 'rate-item--centered' : ''}`}
        >
          <div className="rate-item__label">
            {/* {index == 0 ? 'Fixed Rate' : 'Comparison Rate'} */}
            {rate.label}
          </div>
          <div className="rate-item__items">
            <Rate
              rate={rate.rate1}
              index={index}
            />
            {rate.rate2 && (
              <>
                <div className="rate__to">{type !== 'motor' ? 'to' : 'or'}</div>
                <Rate rate={rate.rate2} index={index} />
              </>
            )}
          </div>
        </Item>
      ))}
    </RatesBox>
  );
};

export default props =>
  !props.isBranded ? <RatesBox {...props} /> : <RateBoxBranded {...props} />;
